import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  HomeWrapper,
  ResponsiveImage,
  ResponsiveZoomImageMobile,
  ResponsiveZoomImageWeb,
  WorkSection,
  WorkSectionCol,
  WorkCol,
  Heading,
  WorkBackSpace,
  StyledComponent,
  TopWrapper,
  GamePlaySection,
  GamePlayCols,
  Requirements,
  RequirementContainer,
  RequirementDiv,
  PlayBackground,
  Team,
  TeamRow,
  TeamCard,
  TeamCardImage,
  StickyButton,
  ShowButton,
  PlayButton,
  ButtonWrapper,
  PartnerSection,
  FaqSection,
  StatsSection,
  // StatsVAlue,
  // StatsHeading,
  // StatsCol,
  LeaderBoard,
  GroupHeading,
  SingleButtonWrapper,
  MultiChainSection,
  PartnerWrap,
  BehindSection,
  BehindWrap,
  CommunityWrap,
  CommunitySection,
  ButtonWrapperCommunity,
  FaqWrap,
  GameShots,
  HeadingBlack,
  HeadingStrong,
  LargeButton,
  Tick,
  Flex,
  CarouselWrapper,
  CarouselImage,
  CarouselWrapperPartner,
  CarouselImagePartner,
  PartnerColumns,
} from "./style";

import first from "../../assets/images/first.jpg";
import work1 from "../../assets/images/work1.svg";
import work2 from "../../assets/images/work2.svg";
import work3 from "../../assets/images/work3.svg";

import game1 from "../../assets/images/game1.svg";
import game2 from "../../assets/images/game2.svg";
import game3 from "../../assets/images/game3.svg";
import game4 from "../../assets/images/game4.svg";
import metamask from "../../assets/images/metamask.svg";
import tick from "../../assets/images/tick.svg";

import power from "../../assets/images/power.svg";
import angle from "../../assets/images/angle.svg";
import perfect from "../../assets/images/perfect.svg";

import innovation from "../../assets/images/innovation.svg";
import rapid from "../../assets/images/rapid.svg";

import matic from "../../assets/images/matic.svg";

import win from "../../assets/images/win.svg";
import { colors } from "../../styles/colors";
import Accordion from "../../components/Accordion";
// import { TwoColumns } from "../Contact";

import ParentComponent from "../../components/Accordion";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from "../../components/Navbar";

const gamePlayData = [
  {
    image: game1,
    title: "Connect Wallet",
  },
  {
    image: game2,
    title: "Set Angle",
  },
  {
    image: game3,
    title: "Set Power",
  },
  {
    image: game4,
    title: "Swing to earn",
  },
];

const requirementData = [
  {
    image: metamask,
    tick: tick,
    title: "Metamask",
    subtitle:
      "MetaMask is a digital wallet for Ethereum and its related tokens, including MATIC. It is available as a browser extension and a mobile app.",
    link: "How to get it",
  },
  {
    image: matic,
    tick: tick,
    title: "Matic Token",
    subtitle:
      "Once you have installed MetaMask, the next step is to configure it to use the Polygon Mumbai Testnet. You'll also need test tokens. ",
    link: "How to get them",
  },
  {
    image: win,
    tick: tick,
    title: "Attitude win",
    subtitle:
      "“A positive attitude is something everyone can work on, and everyone can learn how to employ it.”",
    link: "BY: Captain Jack Sparrow",
  },
];

const teamData = [
  {
    image: win,
    title: "Martin Naithani",
    designation: "Visionary Voyager",
  },
  {
    image: win,
    title: "Eshan Naithani",
    designation: "Metaverse Architect",
  },
  {
    image: win,
    title: "Sebastian Gier",
    designation: "Aesthetics Wizard",
  },
  {
    image: win,
    title: "Evgeny Zakharov",
    designation: "Deadline Defender",
  },
  {
    image: win,
    title: "Sandeep Gusain",
    designation: "Server Samurai",
  },
];

const AdvisorData = [
  {
    image: win,
    title: "Prasanjit Dey",
    designation: "CTO : Rapid Innovation",
  },
  {
    image: win,
    title: "Shailesh kala",
    designation: "Frontend Head : Rapid innovation",
  },
  {
    image: win,
    title: "Jesse Anglen",
    designation: "CEO : Rapid Innovation",
  },
];

const PlayClick = () => {
  const url = "https://swingtoearn.com/Beta/"; // Replace with the URL you want to redirect to
  window.open(url, "_blank");
};

const Studio = () => {
  const url = "https://www.metalityx.com/"; // Replace with the URL you want to redirect to
  window.open(url, "_blank");
};

const getLink = () => {
  const url = "https://www.metalityx.com/post/swing-to-earn-beta-contest-and-how-to-get-started"; // Replace with the URL you want to redirect to
  window.open(url, "_blank");
};




const Landing = React.memo(() => {
  const navigate = useNavigate();
  const ComingSoon = () => {
    navigate("/data");
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts (page changes)
  }, []);

  return (
    <div>
      <HomeWrapper>
        <StickyButton onClick={PlayClick}>Play Game</StickyButton>
        <TopWrapper>
          <StyledComponent>
            <h5>Join us for a round of golf</h5>
            <h1>
              <strong>swing</strong> to earn
            </h1>
            <ButtonWrapper>
              <ShowButton onClick={ComingSoon}>Show Data</ShowButton>
              <PlayButton onClick={PlayClick}>play game</PlayButton>
            </ButtonWrapper>
            <p>Free for a limited time. You can still win.</p>
          </StyledComponent>
        </TopWrapper>

        <GamePlaySection>
          <HeadingBlack>
            <HeadingStrong>Simple</HeadingStrong> gameplay
          </HeadingBlack>
          <GamePlayCols>
            {gamePlayData.map((item, index): any => (
              <div key={index} style={{ margin: "0 0 30px 0" }}>
                <img src={item.image} alt="Item" />
                <h2>{item.title}</h2>
              </div>
            ))}
          </GamePlayCols>
        </GamePlaySection>

        <WorkSection>
          <HeadingBlack>
            How <HeadingStrong>it works</HeadingStrong>
          </HeadingBlack>

          <WorkSectionCol>
            <ResponsiveImage src={work1} alt="Item" />
            <WorkCol>
              <h2>Choose Your Challenge</h2>
              <p>
                Feel the adrenaline course through your veins as you pick your
                preferred Gameplay mode. Whether it's the thrill of "One Shot
                Challenges," the strategy of "Par 3 Challenges," or the glory of
                defeating a fellow player in "P2P Golf Battles," the choice is
                yours.
              </p>
            </WorkCol>
          </WorkSectionCol>

          <WorkSectionCol>
            <ResponsiveZoomImageMobile src={work2} alt="Item" />
            <WorkCol>
              <h2>Next-Level Earnings!</h2>
              <p>
                Swing to Earn isn't just a game - it's a new revenue fairway!
                Crush the competition in P2P Golf Battles or master the One Shot
                and Par 3 challenges to start raking in those crypto rewards.
                Plus, partners can share in the success by leveraging our
                white-label opportunities. So why wait? Grab your virtual clubs
                and let's swing to earn!
              </p>
            </WorkCol>
            <ResponsiveZoomImageWeb src={work2} alt="Item" />
          </WorkSectionCol>

          <WorkSectionCol>
            <ResponsiveImage src={work3} alt="Item" />
            <WorkCol>
              <h2>Reap Your Rewards</h2>
              <p>
                Cashed-out or HODL, it's all on you. Earnings from the
                challenges can be pocketed as rewards or staked back into the
                game for future glory. Remember, the golf course is not just a
                playground; it's your crypto battlefield. Now get out there and
                swing to earn!
              </p>
            </WorkCol>
          </WorkSectionCol>
        </WorkSection>

        <Requirements>
          <HeadingBlack>Requirements</HeadingBlack>

          <RequirementContainer>
            {requirementData.map((item, index): any => (
              <RequirementDiv key={index}>
                <img src={item.image} alt="Item" />
                <div>
                  <Flex>
                    <h2>{item.title}</h2>
                    <Tick src={item.tick} alt="Item" />
                  </Flex>
                  <p>{item.subtitle}</p>
                  <a href="https://www.metalityx.com/post/swing-to-earn-beta-contest-and-how-to-get-started" target="_blank">{item.link}</a>
                </div>
              </RequirementDiv>
            ))}
          </RequirementContainer>
        </Requirements>

        <PlayBackground>
          <HeadingBlack>
            Stop <HeadingStrong>Scrolling,</HeadingStrong> Start
            <HeadingStrong> Swinging!</HeadingStrong>
            <br />
            Play <HeadingStrong> the Game Now!</HeadingStrong>
          </HeadingBlack>

          <SingleButtonWrapper>
            <ShowButton onClick={PlayClick}>Play Now</ShowButton>
          </SingleButtonWrapper>
        </PlayBackground>

        {/* <StatsSection>
          <StatsHeading>statistics</StatsHeading>

          <StatsVAlue>
            <StatsCol>
              <div>
                <p>winnings</p>
                <h1>11,345</h1>
              </div>
              <div>
                <p>Games Played</p>
                <h1>11,345</h1>
              </div>
            </StatsCol>

            <StatsCol>
              <div>
                <p>Swings Made</p>
                <h1>11,234</h1>
              </div>
              <div>
                <p>Amount to win</p>
                <h1>$11,3456</h1>
              </div>
            </StatsCol>
          </StatsVAlue>
        </StatsSection> */}

        <div>
          <HeadingBlack>Leaderboard</HeadingBlack>
          <LeaderBoard></LeaderBoard>
        </div>

        <GameShots>
          <HeadingBlack>
            Gameplay <HeadingStrong>Shots</HeadingStrong>
          </HeadingBlack>
          {/* <ImageCarousel /> */}
          <CarouselWrapper>
            <Carousel
              autoPlay
              interval={3000}
              showThumbs={false}
              showArrows={false}
              showStatus={false}
            >
              <div>
                <CarouselImage src={angle} alt="Image 2" />
              </div>
              <div>
                <CarouselImage src={power} alt="Image 1" />
              </div>
              <div>
                <CarouselImage src={perfect} alt="Image 3" />
              </div>
            </Carousel>
          </CarouselWrapper>
        </GameShots>

        <MultiChainSection>
          <HeadingBlack>
            Multichain <HeadingStrong>Game</HeadingStrong>
          </HeadingBlack>
        </MultiChainSection>

        <PartnerSection>
          <PartnerWrap>
            <HeadingBlack>become a partner</HeadingBlack>
            <p>
              Revenue isn't just about gameplay fees here. We’ve got multiple
              revenue streams – in-game ads, social flex NFT drops, and
              white-label opportunities! Partners can roll our game into their
              own platforms and get a piece of the pie from gameplay fees. It's
              not just about playing the game, it's about owning it.
            </p>
          </PartnerWrap>

          <SingleButtonWrapper>
            <LargeButton onClick={Studio}>Become a Partner</LargeButton>
          </SingleButtonWrapper>
        </PartnerSection>

        <FaqSection>
          <h4>FAQ</h4>
          <ParentComponent />
        </FaqSection>

        <Team>
          <WorkBackSpace>
            <Heading>
              <strong style={{ color: colors.white }}>Meet the team</strong>
            </Heading>
          </WorkBackSpace>

          <h3>CORE TEAM</h3>

          <TeamRow>
            {teamData.map((item, index): any => (
              <TeamCard key={index}>
                <TeamCardImage></TeamCardImage>
                <h1>{item.title}</h1>
                <p>{item.designation}</p>
              </TeamCard>
            ))}
          </TeamRow>

          <h3 style={{ margin: "60px 0 40px 0" }}>Advisors</h3>

          <TeamRow>
            {AdvisorData.map((item, index): any => (
              <TeamCard key={index}>
                <TeamCardImage></TeamCardImage>
                <h1>{item.title}</h1>
                <p>{item.designation}</p>
              </TeamCard>
            ))}
          </TeamRow>
          <h3 style={{ margin: "60px 0 40px 0" }}>Partners</h3>

          <PartnerColumns>
            <CarouselImagePartner src={innovation} alt="Image 1" />
            <CarouselImagePartner src={rapid} alt="Image 1" />
          </PartnerColumns>

          {/* <CarouselWrapperPartner>
            <Carousel
              autoPlay
              interval={3000}
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              centerMode
              centerSlidePercentage={50}
              showIndicators={false}
            >
              <div>
                <CarouselImagePartner src={innovation} alt="Image 1" />
              </div>
              <div>
                <CarouselImagePartner src={rapid} alt="Image 2" />
              </div>
            </Carousel>
          </CarouselWrapperPartner> */}
        </Team>

        <BehindSection>
          <BehindWrap>
            <HeadingBlack>
              <HeadingStrong>Studio </HeadingStrong> Spotlight
            </HeadingBlack>

            <p>
              Swing to earn is developed by MetalityX Studio. With a decade of
              experience building games, MetalityX team is also blockchain
              enthusiasts at heart, they have the expertise and technical
              know-how to create cutting-edge web3 games and a deep
              understanding of game economies and tokenomics that can stand the
              test of time.
            </p>
          </BehindWrap>

          <SingleButtonWrapper>
            <LargeButton onClick={Studio}>Check The Studio</LargeButton>
          </SingleButtonWrapper>
        </BehindSection>

        <CommunitySection>
          <CommunityWrap>
            <h1>
              <strong>Join</strong> Our community
            </h1>
            <p>
              Follow our news and always be the first to know all the updates{" "}
            </p>
          </CommunityWrap>
          <ButtonWrapperCommunity>
            <ShowButton onClick={ComingSoon}>Show Data</ShowButton>
            <PlayButton onClick={PlayClick}>play game</PlayButton>
          </ButtonWrapperCommunity>
        </CommunitySection>
      </HomeWrapper>
    </div>
  );
});

export default Landing;
