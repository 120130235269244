import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { colors } from "../styles/colors";
import first from "../assets/images/dash1.svg";

import work1 from "../assets/images/work1.svg";
import work2 from "../assets/images/work2.svg";
import work3 from "../assets/images/work3.svg";

import { FourColumns, ThreeColumns } from "../styles/globalStyle";
import { MainButton } from "./landing/style";
import ButtonBackground from "../assets/images/orangeButton.svg";
import Table from "../components/Table";
import Dropdown from "../components/Droprdown";
import { click } from "@testing-library/user-event/dist/click";

const ContactContainer = styled.div``;

// Styled Components
const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
`;

const TabHeader = styled.div`
  display: flex;
`;

const TabButton = styled.button<{ active?: boolean }>`
  padding: 10px 20px;
  border: none;
  background-color: ${(props) => (props.active ? colors.green : "transparent")};
  color: ${(props) => (props.active ? colors.white : colors.black)};

  cursor: pointer;
  outline: none;
  border-radius: 4px;
  font-family: "SatoshiBold";
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const TabContent = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
`;

const WrapContent = styled.div`
  padding-top: 100px;
  @media (min-width: 700px) {
    padding-top: 170px;
  }
  @media (min-width: 1800px) {
    padding-top: 238px;
  }
`;

export const ShowButton = styled(MainButton)`
  background-image: url(${ButtonBackground});
`;

export const SoonSection = styled.div`
  background-image: url(${first});
  background-size: cover;
  background-position: top;
  height: auto;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  @media (min-width: 700px) {
    height: 100%;
    background-size: cover;
    background-position: top;
  }
  @media (min-width: 1100px) {
    height: 100%;
    background-size: cover;
    background-position: top;
  }

  p {
    color: ${colors.black};
    text-align: center;
    font-family: SatoshiRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin: 20px;
    @media (min-width: 700px) {
      width: 50%;
      margin: 70px auto;
    }
  }
`;
export const HeadingBlack = styled.h4`
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  font-family: DrukWide;
  font-weight: 700;
  letter-spacing: -0.56px;
  text-transform: uppercase;
  color: ${colors.black};
  padding-top: 50px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 45px;
  }
  @media (min-width: 1024px) {
    font-size: 56px;
  }
`;
export const HeadingStrong = styled.strong`
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  font-family: DrukWide;
  font-weight: 700;
  letter-spacing: -0.56px;
  text-transform: uppercase;
  color: ${colors.green};
  padding-top: 50px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 45px;
  }

  @media (min-width: 1024px) {
    font-size: 56px;
  }
`;
export const CardWrapper = styled.div`
  margin: 20px;

  @media (min-width: 700px) {
    font-size: 40px;
  }
  @media (min-width: 1024px) {
    font-size: 80px;
  }
`;

const zoomAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;
export const Card = styled.div`
  text-align: center;
  margin-bottom: 30px;
  p {
    color: ${colors.black};
    text-align: center;
    font-family: SatoshiRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    width: 100%;
    margin: 0;
  }

  h1 {
    color: ${colors.black};
    text-align: center;
    font-family: DrukWide;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 30px 0;
  }
  img {
    height: 140px;
    &:hover {
      animation: ${zoomAnimation} 2s infinite;
    }
  }
`;

export const DataSet = styled.div`
  margin: 20px;
  @media (min-width: 1024px) {
    margin: 60px 100px;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexDiv = styled.div`
  display: flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;
export const DataCol = styled.div`
  h1 {
    color: ${colors.green};
    font-family: DrukWide;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    text-align: center;
  }
  h3 {
    color: ${colors.black};
    font-family: DrukWide;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    width: 100%;
    margin: 10px 0;
    text-align: center;
  }
`;

export const TableHeading = styled.div`
  color: ${colors.black};
  font-family: DrukWide;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

const requirementData = [
  {
    image: work1,
    title: "Empowering Your Insight",
    subtitle:
      " Our powerful Transparency Dashboard offers an unfiltered look into every aspect of the game. No secrets, no hidden scores - just real-time data that empowers you to make informed decisions and enjoy the game with full confidence.",
  },
  {
    image: work2,
    title: "Built on Trust",
    subtitle:
      "We take trust seriously. With the Transparency Dashboard, you'll have complete visibility into your gameplay, rewards, and every move on the digital fairways. We believe that trust is the foundation of every great game, and we're dedicated to fostering an environment where you can play with peace of mind.",
    link: "",
  },
  {
    image: work3,
    title: "Power of Honesty",
    subtitle:
      "Swing to Earn is rooted in integrity, and our Transparency Dashboard is a testament to that commitment. As we continue to evolve, rest assured that honesty will always remain one of our top priorities, making your gaming experience truly exceptional.",
    link: "",
  },
];

const Contact = () => {
  const [activeTab, setActiveTab] = useState("");

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const navigate = useNavigate();
  const ToDetails = (id: any) => {
    navigate(`/userinfo/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts (page changes)
  }, []);

  const [tableData, setTableData] = useState([]);
  const [sortKey, setSortKey] = useState("totalAttempts"); // Default value
  const [sortOrder, setSortOrder] = useState("-1"); // Default value

  const sortKeys = [
    { heading: "TotalAttempts", value: "totalAttempts" },
    { heading: "Total Win", value: "totalWin" },
    { heading: "Win Percent", value: "winPercent" },
    { heading: "Total Point", value: "totalPoint" },
  ];

  const sortOrders = [
    { heading: "High To Low", value: "-1" },
    { heading: "Low To High", value: "1" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://back.swingtoearn.com/winnerdata?sortKey=${sortKey}&sortOrder=${sortOrder}&modeName=${activeTab}`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        const manipulatedData = result.map((item: any, index: any) => {
          return {
            ...item,
            winPercent: ` ${item.winPercent} %`,
          };
        });
        setTableData(manipulatedData);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, [sortKey, sortOrder, activeTab]);

  const columns = [
    {
      Header: "#",
      accessor: "rank",
    },
    {
      Header: "Wallet address",
      accessor: "walletAddress",
    },
    {
      Header: "Attempts",
      accessor: "totalAttempts",
    },
    {
      Header: "Wins",
      accessor: "totalWin",
    },
    {
      Header: "Win percentage",
      accessor: "winPercent",
    },
  ];

  return (
    <ContactContainer>
      <SoonSection>
        <WrapContent>
          <HeadingBlack>
            Transparency <HeadingStrong>Dashboard</HeadingStrong>
          </HeadingBlack>
          <p>
            Experience a new era of absolute transparency and honesty with Swing
            to Earn's upcoming Transparency Dashboard. At Swing to Earn, we
            believe that honesty is paramount, and our commitment to
            transparency is unwavering.
          </p>
          <CardWrapper>
            <ThreeColumns>
              {requirementData.map((item, index): any => (
                <Card key={index}>
                  <img src={item.image} alt="Item" />
                  <h1>{item.title}</h1>
                  <p>{item.subtitle}</p>
                </Card>
              ))}
            </ThreeColumns>
          </CardWrapper>

          <DataSet>
            {/* <FourColumns>
              <DataCol>
                <h1>Winnings</h1>
                <h3>11467</h3>
              </DataCol>
              <DataCol>
                <h1>Games Played</h1>
                <h3>11467</h3>
              </DataCol>
              <DataCol>
                <h1>Swings Made</h1>
                <h3>11467</h3>
              </DataCol>
              <DataCol>
                <h1>Amount to win</h1>
                <h3>11467</h3>
              </DataCol>
            </FourColumns> */}

            <TableHeading>Leaderboard</TableHeading>

            {/* <FlexDiv>
                      <Flex>
                        <Dropdown
                          label="Sort By"
                          options={sortKeys}
                          onChange={(value: any) => setSortKey(value)}
                        />
                        <Dropdown
                          label="Order"
                          options={sortOrders}
                          onChange={(value: any) => setSortOrder(value)}
                        />
                      </Flex>
                    </FlexDiv>
                    <Table
                      columns={columns}
                      data={tableData}
                      navigateToDetails={true}
                    /> */}

            <TabContainer>
              <TabHeader>
                <TabButton
                  active={activeTab === ""}
                  onClick={() => handleTabClick("")}
                >
                  All
                </TabButton>
                <TabButton
                  active={activeTab === "easy"}
                  onClick={() => handleTabClick("easy")}
                >
                  Easy Mode
                </TabButton>
                <TabButton
                  active={activeTab === "medium"}
                  onClick={() => handleTabClick("medium")}
                >
                  Medium Mode
                </TabButton>
              </TabHeader>
              <TabContent>
                <FlexDiv>
                  <Flex>
                    <Dropdown
                      label="Sort By"
                      options={sortKeys}
                      onChange={(value: any) => setSortKey(value)}
                    />
                    <Dropdown
                      label="Order"
                      options={sortOrders}
                      onChange={(value: any) => setSortOrder(value)}
                    />
                  </Flex>
                </FlexDiv>
                <Table
                  columns={columns}
                  data={tableData}
                  navigateToDetails={true}
                />

                {/* {activeTab === "easy" && (
                  <div>
                    <FlexDiv>
                      <Flex>
                        <Dropdown
                          label="Sort By"
                          options={sortKeys}
                          onChange={(value: any) => setSortKey(value)}
                        />
                        <Dropdown
                          label="Order"
                          options={sortOrders}
                          onChange={(value: any) => setSortOrder(value)}
                        />
                      </Flex>
                    </FlexDiv>
                    <Table
                      columns={columns}
                      data={tableData}
                      navigateToDetails={true}
                    />
                  </div>
                )}
                {activeTab === "medium" && (
                  <div>
                    <FlexDiv>
                      <Flex>
                        <Dropdown
                          label="Sort By"
                          options={sortKeys}
                          onChange={(value: any) => setSortKey(value)}
                        />
                        <Dropdown
                          label="Order"
                          options={sortOrders}
                          onChange={(value: any) => setSortOrder(value)}
                        />
                      </Flex>
                    </FlexDiv>
                    <Table
                      columns={columns}
                      data={tableData}
                      navigateToDetails={true}
                    />
                  </div>
                )} */}
              </TabContent>
            </TabContainer>
          </DataSet>
        </WrapContent>
      </SoonSection>
    </ContactContainer>
  );
};

export default Contact;
