import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { toggleTheme } from "../store/themeSlice";
import logo from "../assets/images/logo.svg";
import ButtonBackground from "../assets/images/playbutton.svg";
import { colors } from "../styles/colors";

interface NavbarProps {
  scrolled: boolean;
}

const NavbarWrapper = styled.nav<NavbarProps>`
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.text};
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  background: #a7ff35;
  z-index: 1000;
  background-color: ${(props) => (props.scrolled ? "#419036" : "transparent")};
  transition: background-color 0.3s ease;
`;

const Logo = styled.img`
  height: 40px;
  @media (min-width: 700px) {
    font-size: 143px;
    height: 80px;
  }
`;
const NavWrap = styled.div`
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 700px) {
    padding: 10px 20px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const NavLink = styled.a<NavbarProps>`
font-family: 'SatoshiMedium';
 color: ${(props) => (props.scrolled ? colors.white : colors.black)};
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 700px) {
    font-size: 16px;
  }
`;

const ToggleThemeButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const PlayButton = styled.div`
background-image: url(${ButtonBackground});
font-style: normal;
font-family: DrukWide;
font-weight: 500;
font-size: 7px;
line-height: 28px;
text-align: center;
text-transform: uppercase;
color: ${colors.white};
outline: none;
border: none;
background-size: contain;
width:118px;
height: 50px;
background-repeat: no-repeat;
cursor: pointer;
padding: 0;
margin-top: 18px;


@media (min-width: 700px) {
  width:252px;
  height: 63px;
  padding: 15px 25px;
  font-size: 16px;
  margin-top: 0;
}

`;

const Navbar = () => {
  const [navbarScrolled, setNavbarScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = window.pageYOffset;
      const navbarHeight = 100; // Change this to the desired scroll height

      if (scrollHeight > navbarHeight) {
        setNavbarScrolled(true);
      } else {
        setNavbarScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const dispatch = useDispatch();

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };

  const PlayClick = () => {
    const url = 'https://swingtoearn.com/Beta/'; // Replace with the URL you want to redirect to
    window.open(url, '_blank');
  };

  const Discord = () => {
    const url = 'https://discord.com/invite/wqBreaMc'; // Replace with the URL you want to redirect to
    window.open(url, '_blank');
  };
  const Studio = () => {
    const url = 'https://www.metalityx.com/'; // Replace with the URL you want to redirect to
    window.open(url, '_blank');
  };

 
  const navigate = useNavigate();
  const Home = () => {
    navigate("/");
  };
 

  return (
    <NavbarWrapper scrolled={navbarScrolled}>
      <NavWrap>
        <LinksWrapper>
            {/* <NavLink to="/">Home</NavLink> */}
            <NavLink  onClick={Studio} scrolled={navbarScrolled}>Check Studio</NavLink>
            <NavLink  onClick={Discord} scrolled={navbarScrolled}>Join Discord</NavLink>
        </LinksWrapper>

        <Logo onClick={Home} src={logo} alt="" style={{cursor:'pointer'}} />

        <PlayButton onClick={PlayClick}> Play for Free</PlayButton>
      </NavWrap>
    </NavbarWrapper>
  );
};

export default Navbar;
