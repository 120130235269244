import styled, { keyframes } from "styled-components";
import workBack from "../../assets/images/workback.svg";
import top from "../../assets/images/top.svg";

import play from "../../assets/images/more.png";
import Avatar from "../../assets/images/Avatar2.svg";
import ButtonBackground from "../../assets/images/orangeButton.svg";
import LargeButtonBackground from "../../assets/images/LargeButton.svg";

import GreenBackground from "../../assets/images/greenButton.svg";
import PartnerBackground from "../../assets/images/partner.svg";
import FAQBackground from "../../assets/images/FAQ.svg";
import TeamBackground from "../../assets/images/team.svg";
import StatsBackground from "../../assets/images/Statistics.svg";
import RequirementBackground from "../../assets/images/requirements.svg";
import chain from "../../assets/images/Multichain.svg";
import Behind from "../../assets/images/behind.svg";
import Community from "../../assets/images/community.jpg";
import HowTo from "../../assets/images/howto.svg";
import Works from "../../assets/images/work-r.svg";
import shots from "../../assets/images/shots.svg";


import stat from "../../assets/images/stat-img.svg";

import { colors } from "../../styles/colors";
import {
  FourColumns,
  ThreeColumns,
  TwoColumns,
} from "../../styles/globalStyle";

export const PartnerColumns = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 50px;
  max-width:100%;
  margin:auto;

  @media (min-width: 600px) {
    grid-gap: 25px;
    grid-template-columns: repeat(2, 1fr);
    max-width:50%;
    margin:auto;
  }
  @media (min-width: 1400px) {
    grid-gap: 25px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const HomeWrapper = styled.div``;

export const CarouselWrapper = styled.div`
margin-top:20px;
@media (min-width: 700px) {
  margin-top:100px;
}
`;

export const CarouselImage = styled.img`
height:300px;
@media (min-width: 700px) {
  height:500px;
}
@media (min-width: 1024px) {
  height:700px;
}
`;


export const CarouselWrapperPartner = styled.div`
margin-top:50px;
padding-bottom:50px;
`;

export const CarouselImagePartner = styled.img`
height: 150px;
width: 100%;
margin-bottom: 40px;
`;

export const HeadingBlack = styled.h4`
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  font-family: DrukWide;
  font-weight: 700;
  letter-spacing: -0.56px;
  text-transform: uppercase;
  color: ${colors.black};
  padding-top: 50px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 45px;
  }

  @media (min-width: 1024px) {
    font-size: 56px;
  }
`;
export const HeadingStrong = styled.strong`
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  font-family: DrukWide;
  font-weight: 700;
  letter-spacing: -0.56px;
  text-transform: uppercase;
  color: ${colors.green};
  padding-top: 50px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 45px;
  }

  @media (min-width: 1024px) {
    font-size: 56px;
  }
`;

export const TopWrapper = styled.div`
  background-image: url(${top});
  background-size: cover;
  background-position: center center;
  height: 100vh;
  margin-top: 50px;
  @media (min-width: 700px) {
    height: 100vh;
    margin-top: 150px;
  }
`;

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  animation: slideDown 1s ease-in-out 0.5s forwards;

  h5 {
    font-family: "SatoshiMedium";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin: 0;
    @media (max-width: 700px) {
    }
  }
  p {
    font-family: "SatoshiMedium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin: 0;
  }

  h1 {
    text-align: center;
    font-size: 40px;
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    text-transform: uppercase;
    margin-top: 10px;
    @media (min-width: 700px) {
      font-size: 80px;
    }
  }
  strong {
    color: ${colors.green};
    text-align: center;
    font-size: 40px;
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    text-transform: uppercase;
    @media (min-width: 700px) {
      font-size: 80px;
    }
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const StyledImage = styled.div`
  display: flex;
  button {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: ${colors.green};
    color: ${colors.black};
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    border-radius: 50px;
    font-weight: 700;
  }
`;

export const WorkBackSpace = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 700px) {
    padding-top: 130px;
  }
`;

export const Heading = styled.h1`
  font-family: DrukWide;
  color: ${colors.green};
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  text-align: center;
  strong {
    color: ${colors.black};
    font-family: DrukWide;
  }


  @media (min-width: 700px) {
    font-size: 56px;
    line-height: 67px;
  }
`;

const upDownAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-23px);
  }
  100% {
    transform: translateY(0);
  }
`;
const zoomAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;
const upAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export const ResponsiveImage = styled.img`
  height: 280px;
  animation: ${upDownAnimation} 1.7s infinite;
  width: 100%;
  @media (min-width: 700px) {
    height: 320px;
  }
  @media (min-width: 1400px) {
    height: 482px;
  }
`;
export const Tick = styled.img`
  height: 50px !important;
  height: 50px !important;
  padding-top:0 !important;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const ResponsiveZoomImageMobile = styled.img`
  height: 320px;
  width: 100%;
  display: block;
  @media (min-width: 700px) {
    display: none;
  }
  &:hover {
    animation: ${zoomAnimation} 3s infinite;
  }
`;

export const ResponsiveZoomImageWeb = styled.img`
  height: 482px;
  width: 100%;
  display: none;
  @media (min-width: 700px) {
    display: block;
  }

  &:hover {
    animation: ${zoomAnimation} 3s infinite;
  }
`;

export const GamePlaySection = styled.div`
  background-image: url(${HowTo});
  background-size: contain;
  background-position: top center;
  height: 100%;
  margin-top: 50px;
  background-repeat: no-repeat;

  @media (min-width: 700px) {
    margin-top: 100px;
    height: 850px;
    background-size: cover;
    background-position: top center;
  }

  @media (min-width: 1024px) {
    margin-top: 100px;
    height: 650px;
  }
  h4 {
    padding-top: 15px;
    @media (min-width: 700px) {
      padding-top: 80px;
    }
  }
`;

export const GamePlayCols = styled(FourColumns)`
  align-items: center;
  vertical-align: middle;
  text-align: center;
  margin: 20px;
  @media (min-width: 700px) {
    margin-top: 100px;
  }

  img {
    height: 160px;
    transition: all 0.3s ease;
    &:hover {
      transform: translateY(-7px);
    }
  }
  h2 {
    margin-top: 30px;
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.black};

    @media (min-width: 700px) {
      font-size: 26px;
    }
  }
`;

export const WorkSection = styled.div`
  background-image: url(${Works});
  background-size: cover;
  background-position: top center;
  height: 100%;
  margin-top: 50px;

  @media (min-width: 700px) {
    margin-top: 0;
    height: 1400px;
  }

  @media (min-width: 1024px) {
    margin-top: 0;
    height: 1800px;
  }

  h4 {
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    ${HeadingBlack}
  }
  strong {
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    ${HeadingStrong}
  }
`;

export const WorkSectionCol = styled(TwoColumns)`
  margin: 50px 20px 0 20px;

  @media (min-width: 700px) {
    margin: 50px 20px 0 20px;
  }

  @media (min-width: 1024px) {
    margin: 100px 100px 0 100px;
  }
`;
export const WorkCol = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
  @media (min-width: 700px) {
    margin: auto 100px auto 50px;
  }

  h2 {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: ${colors.black};

    @media (min-width: 700px) {
      line-height: 40px;
      font-size: 32px;
    }

    @media (min-width: 1024px) {
      line-height: 48px;
      font-size: 40px;
    }
  }

  p {
    font-family: "SatoshiMedium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black};
  }
`;

export const Requirements = styled.div`
  background-image: url(${RequirementBackground});
  background-size: contain;
  background-position: top center;
  margin-top: 50px;
  background-repeat: no-repeat;

  @media (min-width: 700px) {
    margin-top: 150px;
    background-size: cover;
    background-position: center center;
  }
  h4 {
    padding-top: 38px;
    @media (min-width: 700px) {
      padding-top: 100px;
    }
  }
`;

export const RequirementContainer = styled.div`
  margin: 50px 20px 0 20px;
  max-width: 810px;

  @media (min-width: 700px) {
    margin: 100px 20px;
  }

  @media (min-width: 1024px) {
    margin: 100px auto 100px auto;
  }
`;
export const RequirementDiv = styled.div`
  display: grid;
  grid-template-columns: auto;
  margin-bottom: 40px;

  @media (min-width: 700px) {
    grid-template-columns: 2fr 10fr;
  }

  img {
    height: 100px;
    width: 100px;
    margin-right: 30px;
    padding-top: 22px;
    &:hover {
      animation: ${zoomAnimation} 3s infinite;
    }

  }

  h2 {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${colors.black};
    margin-top: 15px;
  }

  p {
    font-family: "SatoshiMedium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black};
  }

  a {
    font-family: "SatoshiMedium";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    color: ${colors.green};

    text-decoration: none;
  }
`;

export const PlayBackground = styled.div`
  background-image: url(${play});
  background-size: cover;
  background-position: center center;
  height: 400px;
  @media (min-width: 700px) {
    height: 680px;
  }
  h4 {
    padding-top: 50px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.4px;

    @media (min-width: 700px) {
      font-size: 30px;
      line-height: 48px;
    }

  }
  strong {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.4px;
    @media (min-width: 700px) {
      font-size: 30px;
      line-height: 48px;
    }
  }
`;

export const Team = styled.div`
  background-image: url(${TeamBackground});
  background-size: cover;
  background-position: top;
  h3 {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: ${colors.white};
    margin-bottom: 40px;
    margin-top: 30px;

    @media (min-width: 700px) {
      margin-top: 50px;
      font-size: 26px;
      line-height: 43px;
    }

    @media (min-width: 1024px) {
      margin-top: 80px;
      font-size: 36px;
      line-height: 43px;
    }

  }
`;
export const TeamRow = styled(ThreeColumns)`
  max-width: 1016px;
  margin: 20px;

  @media (min-width: 700px) {
    margin: 20px;
  }
  @media (min-width: 1024px) {
    margin: auto;
  }
`;

export const TeamCard = styled.div`
margin-bottom: 20px;
  h1 {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.white};
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  }
  p {
    font-family: SatoshiMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.white};
    text-align: center;
    margin: 0;
  }
`;

export const TeamCardImage = styled.div`
  background-image: url(${Avatar});
  background-size: cover;
  height: 322px;
  border-radius: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-10px);
  }
`;

export const StickyButton = styled.button`
background: linear-gradient(176.57deg, #FF1D4F 4.95%, #E31C50 33.84%, #C71B51 69.52%, #BD1B52 89.91%);
box-shadow: 2px 6px 12px rgba(39, 5, 14, 0.15);
position fixed;
bottom: 300px;
right: -45px;
font-family: "Nunito", sans-serif;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
text-align: center;
letter-spacing: 0.05em;
text-transform: uppercase;
z-index: 999;
color: ${colors.white};
    transform: rotate(-90deg);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    outline: none;
    padding: 20px 25px;
    border: none;
    cursor: pointer;
`;

export const MainButton = styled.div`
  font-family: DrukWide;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
  outline: none;
  padding: 0;
  border: none;
  width: 130px;
  height: 40px;
  background-repeat: no-repeat;
  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-size: contain;

  &:hover {
    transform: translateY(-5px);
  }

  @media (min-width: 700px) {
    width: 262px;
    height: 82px;
    font-size: 16px;
    padding: 20px 25px;
  }
`;

export const PlayButton = styled(MainButton)`
  background-image: url(${GreenBackground});
  margin-left: 30px;
`;

export const ShowButton = styled(MainButton)`
  background-image: url(${ButtonBackground});
`;
export const LargeButton = styled(MainButton)`
  background-image: url(${LargeButtonBackground});
  width: 323px;
  height: 63px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px 0;
`;

export const PartnerSection = styled.div`
  background-image: url(${PartnerBackground});
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (min-width: 700px) {
    height: 450px;
  }
  @media (min-width: 1024px) {
    height: 540px;
  }
  h4 {
    color: ${colors.white};
    padding-top: 20px;
  }
  p {
    font-family: SatoshiMedium;
    color: ${colors.white};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.176px;
    text-align: center;
    margin-top: 20px;

    @media (min-width: 700px) {
      margin-top: 50px;
      margin: 20px 20px 0 20px;
    }

    @media (min-width: 1024px) {
      margin-top: 100px;
      margin: 20px 0 0 0;
    }
  }
`;

export const PartnerWrap = styled.div`
  margin: auto 20px;
  padding: 20px 0 0 0;
  @media (min-width: 700px) {
    padding: 50px 0 0 0;
    margin: auto;
  }

  @media (min-width: 1024px) {
    width: 60%;
    padding: 120px 0 0 0;
    margin: auto;
  }

`;

export const BehindSection = styled.div`
  background-image: url(${Behind});
  background-size: cover;
  background-position: center;
  height: 800px;

  @media (min-width: 700px) {
    height: 800px;
  }

  @media (min-width: 1024px) {
    height: 1000px;
  }

  @media (min-width: 1400px) {
    height: 1200px;
  }
  h4 {
    padding-top: 0px;
  }

  p {
    font-family: SatoshiMedium;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.176px;
    text-align: center;
    color: ${colors.black};
    margin: 0 20px;
    padding-top: 20px;

    @media (min-width: 700px) {
      width: 50%;
      margin: auto;
    }
  }
`;

export const BehindWrap = styled.div`
  padding: 140px 0 0 0;

  @media (min-width: 700px) {
    padding: 140px 0 0 0;
  }

  @media (min-width: 1024px) {
    padding: 200px 0 0 0;
  }
`;

export const CommunitySection = styled.div`
  background-image: url(${Community});
  background-size: contain;
  background-position: top center;
  height: 100%;
  background-repeat: no-repeat;


  @media (min-width: 700px) {
    background-size: cover;
    background-position: center;
    height: 400px;
  }


  @media (min-width: 1024px) {
    background-size: cover;
    background-position: center;
    height: 700px;
  }


  h1 {
    font-family: DrukWide;
    color: #fff;
    font-size:30px;
    font-weight: 700;
    letter-spacing: -0.56px;
    text-transform: uppercase;
    color: ${colors.black};
    text-align: center;

    @media (min-width: 700px) {
      font-size: 46px;
    }

    @media (min-width: 1024px) {
      font-size: 56px;
    }

  }
  strong {
    font-family: DrukWide;
    color: #fff;
    font-size:30px;
    font-weight: 700;
    letter-spacing: -0.56px;
    text-transform: uppercase;
    color: ${colors.green};
    text-align: center;
    @media (min-width: 700px) {
      font-size: 56px;
    }
  }


  p {
    font-family: SatoshiMedium;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.176px;
    text-align: center;
    color: ${colors.black};
    margin: 0 20px;
    padding-top: 90px;
    @media (min-width: 700px) {
      margin: auto;
      width: 50%;
      padding-top: 20px;
    }
  }
`;

export const CommunityWrap = styled.div`
  padding: 40px 0 0 0;
  @media (min-width: 700px) {
    padding: 90px 0 0 0;
  }
  @media (min-width: 1024px) {
    padding: 180px 0 0 0;
  }
`;
export const ButtonWrapperCommunity = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 50px auto 0 auto;

  @media (min-width: 700px) {
    width: 70%;
  }

  @media (min-width: 1024px) {
    width: 40%;
  }
`;

export const FaqSection = styled.div`
  background-image: url(${FAQBackground});
  background-size: contain;
  background-position: top;
  height: 100%;
  background-repeat: no-repeat;

  @media (min-width: 700px) {
    height: 1200px;
    background-size: cover;
    background-position: center;
  }

  h4 {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: ${colors.black};
    text-align: center;
    padding-top: 36px;
    @media (min-width: 700px) {
      padding-top: 200px;
    }
  }
`;

export const FaqWrap = styled.div`
  margin: 20px;
  @media (min-width: 700px) {
    margin: 100px;
  }

`;

export const StatsSection = styled.div`
  background-image: url(${StatsBackground});
  background-size: cover;
  background-position: top;
  margin: 100px 0;

  h4 {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: ${colors.black};
  }
`;
export const StatsHeading = styled.h2`
  padding-top: 60px;
  font-family: DrukWide;
  color: ${colors.black};
  font-weight: 700;
  font-size: 56px;
  line-height: 67px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  text-align: center;
`;

export const GroupHeading = styled.h2`
  padding-top: 32px;
  font-family: DrukWide;
  color: ${colors.black};
  font-weight: 700;
  font-size: 56px;
  line-height: 67px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  text-align: center;
`;

export const StatsVAlue = styled.div`
  margin: 110px 0;
  p {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: ${colors.green};
    margin: 0;
  }
  h1 {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: ${colors.black};
  }
`;

export const StatsCol = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 100px;
`;

export const SingleButtonWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
`;

export const LeaderBoard = styled.div`
  background-image: url(${stat});
  background-size: contain;
  background-position: top;
  height: 300px;
  margin: auto;
  margin-top: 50px;
  background-repeat: no-repeat;
  animation: ${upDownAnimation} 1.7s infinite;

  @media (min-width: 700px) {
    height: 600px;
    background-size: contain;
  }

  @media (min-width: 1024px) {
    width: 60%;
    height: 700px;
    background-size: cover;
  }

`;

export const MultiChainSection = styled.div`
  background-image: url(${chain});
  background-size: contain;
  background-position: center;
  height: 300px;
  margin-top: 20px;
  background-repeat: no-repeat;


  @media (min-width: 1024px) {
    font-size: 56px;
    padding-top: 50px;
  }

  @media (min-width: 700px) {
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    height: 600px;
    margin-top: 100px;
  }

  @media (min-width: 1024px) {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 850px;
    margin-top: 100px;
  }
  @media (min-width: 1400px) {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1100px;
    margin-top: 100px;
  }

h4{
  font-size: 25px;
  padding-top: 40px;

  @media (min-width: 700px) {
    font-size: 40px;
    padding-top: 10px;
  }
  @media (min-width: 1024px) {
    font-size: 56px;
    padding-top: 50px;
  }

strong{
  font-size: 25px;
  @media (min-width: 700px) {
    font-size: 56px;
  }
}

`;

export const GameShots = styled.div`
  background-image: url(${shots});
  background-size: cover;
  background-position: top;
  margin-top: 50px;

  @media (min-width: 700px) {
   margin-top: 100px;
  }

  h4 {
    padding-top: 15px;
    @media (min-width: 700px) {
      padding-top: 35px;
    }
  }

`;


