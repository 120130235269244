import styled, { createGlobalStyle, ThemeProps, css } from 'styled-components'

// import NunitoBold from '../assets/fonts/Nunito-Bold.ttf'
// import NunitoLight from '../assets/fonts/Nunito-Light.ttf'
// import NunitoMedium from '../assets/fonts/Nunito-Medium.ttf'
// import NunitoExtraBold from '../assets/fonts/Nunito-ExtraBold.ttf'


import SatoshiBold from '../assets/fonts/Satoshi/Satoshi-Bold.otf'
import SatoshiLight from '../assets/fonts/Satoshi/Satoshi-Light.otf'
import SatoshiMedium from '../assets/fonts/Satoshi/Satoshi-Medium.otf'
import SatoshiRegular from '../assets/fonts/Satoshi/Satoshi-Regular.otf'
import DrukWide from '../assets/fonts/DrukWideCy-Medium-1.ttf'




 


export const LinkStyle = css`
  color: ${(props) => props.theme.accent};
  cursor: pointer;
`

export const GlobalStyle = createGlobalStyle`


@font-face {
  font-family: 'SatoshiBold';
  src: url(${SatoshiBold}) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SatoshiLight';
  src: url(${SatoshiLight}) format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'SatoshiMedium';
  src: url(${SatoshiMedium}) format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'SatoshiRegular';
  src: url(${SatoshiRegular}) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DrukWide';
  src: url(${DrukWide}) format('opentype');
  font-weight: normal;
  font-style: normal;
}


  h1,h2,h3,h4{
    margin: 0;
  }
 
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${(props) => props.theme.secondary};
  }
  html,
  body {
    font-size: 16px;
    height: 100%;
    scroll-behavior: smooth;
    width: 100%;
  }
  body {
    min-height: 100%;
    margin: 0;
    padding: 0;
  }
  *::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
    border-radius: 4px;
  }
  *::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: #9CA6AD;
    border-radius: 20px;
    background-clip: content-box;
  }
  #root{
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    max-width: 100%;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    font-family: NunitoSans;
  }
 

  input:disabled ~ label{
   color: red;
  }
  input[type="checkbox"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  -ms-appearance: radio; 
  }
 

  a {
    ${LinkStyle};
  }
  a.disabled {
    pointer-events: none;
  }


  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

export const FourColumns = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 50px;
  @media (min-width: 600px) {
    grid-gap: 25px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1400px) {
    grid-gap: 25px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const TwoColumns = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 50px;
  @media (min-width: 600px) {
    grid-gap: 25px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1400px) {
    grid-gap: 25px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ThreeColumns = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 50px;
  @media (min-width: 600px) {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1400px) {
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

