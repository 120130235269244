import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { colors } from "../styles/colors";
import first from "../assets/images/userinfo.svg";
import back from "../assets/images/backicon.svg";
import { MainButton } from "./landing/style";
import ButtonBackground from "../assets/images/orangeButton.svg";
import Table from "../components/Table";
import { useParams } from "react-router";

const ContactContainer = styled.div``;
const WrapContent = styled.div`
  padding-top: 100px;
  @media (min-width: 700px) {
    padding-top: 100px;
  }
  @media (min-width: 1800px) {
    padding-top: 238px;
  }
`;

export const ShowButton = styled(MainButton)`
  background-image: url(${ButtonBackground});
`;

export const SoonSection = styled.div`
  background-image: url(${first});
  background-size: cover;
  background-position: top;
  height: auto;
  background-repeat: no-repeat;
  padding-bottom: 50px;
  @media (min-width: 700px) {
    height: 100%;
    background-size: cover;
    background-position: top;
  }
  @media (min-width: 1100px) {
    height: 100%;
    background-size: cover;
    background-position: top;
  }

  p {
    color: ${colors.black};
    text-align: center;
    font-family: SatoshiRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin: 20px;
    @media (min-width: 700px) {
      width: 50%;
      margin: 70px auto;
    }
  }
`;

export const DataSet = styled.div`
  margin: 20px;
  @media (min-width: 1024px) {
    margin: 100px 100px;
  }
`;

export const Overflow = styled.div`
  overflow-x: auto;
  @media (min-width: 1024px) {
    overflow-x: none;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexDiv = styled.div`
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const TableHeading = styled.div`
  color: ${colors.black};
  font-family: DrukWide;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 20px;
`;

export const Claim = styled.button`
  background: ${colors.green};
  color: ${colors.white};
  font-family: SatoshiRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.14px;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  padding: 10px;
  width: 150px;
`;

// Styled Components
const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
`;

const TabHeader = styled.div`
  display: flex;
`;

const TabButton = styled.button<{ active?: boolean }>`
  padding: 10px 20px;
  border: none;
  background-color: ${(props) => (props.active ? colors.green : "transparent")};
  color: ${(props) => (props.active ? colors.white : colors.black)};

  cursor: pointer;
  outline: none;
  border-radius: 4px;
  font-family: "SatoshiBold";
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const TabContent = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
`;

const UserInfo = () => {
  const [activeTab, setActiveTab] = useState("");

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts (page changes)
  }, []);

  const formatWalletAddress = (address: any) => {
    const prefix = address.substr(0, 6); // Get the first 6 characters
    const suffix = address.substr(-4); // Get the last 4 characters
    return `${prefix}...${suffix}`; // Concatenate with dots in between
  };

  const { id } = useParams();
  const formattedAddress = formatWalletAddress(id);
  console.log(
    "🚀 ~ file: Userinfo.tsx:337 ~ UserInfo ~ formattedAddress:",
    formattedAddress
  );

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://back.swingtoearn.com/winnerdata?winnerAddress=${id}&modeName=${activeTab}`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();

        const manipulatedData = result.map((item: any, index: any) => {
          // Modify item as needed.
          // For example, you could return a new object with specific changes:
          return {
            ...item,
            gameEndDate: formatDate(item.gameEndDate),
            wagerSats: hexToMatic(item.wagerSats) + "Matic",
            result: renderResult(item.result),
            index: `#${index + 1}`,
          };
        });

        setTableData(manipulatedData);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, [id,activeTab]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const time = date.toUTCString().split(" ")[4];

    // Correctly type the options object
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    return `${time} UTC\n${formattedDate}`;
  };


const hexToMatic = (hexValue: any) => {
  const weiToMaticFactor = 1e-18;
  const maticValue = hexValue * weiToMaticFactor;
  return parseFloat(maticValue.toFixed(9));
};

  const renderResult = (result: boolean) => {
    return result ? "Win" : "Lose";
  };

  const navigate = useNavigate();

  const Data = () => {
    navigate("/data");
  };

  const columns = [
    {
      Header: "#",
      accessor: "index",
    },
    {
      Header: "Game Id",
      accessor: "gameId",
    },
    {
      Header: "Date/Time",
      accessor: "gameEndDate",
    },
    {
      Header: "Waged",
      accessor: "wagerSats",
    },
    {
      Header: "Result",
      accessor: "result",
    },
  ];

  return (
    <ContactContainer>
      <SoonSection>
        <WrapContent>
          <DataSet>
            <FlexDiv>
              <Flex>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={Data}
                  src={back}
                  alt="back"
                />
                <TableHeading>{formattedAddress}</TableHeading>
              </Flex>
              {/* <Claim>Claim Rewards</Claim> */}
            </FlexDiv>

            <TabContainer>
              <TabHeader>
              <TabButton
                  active={activeTab === ""}
                  onClick={() => handleTabClick("")}
                >
                  All
                </TabButton>
                <TabButton
                  active={activeTab === "easy"}
                  onClick={() => handleTabClick("easy")}
                >
                  Easy Mode
                </TabButton>
                <TabButton
                  active={activeTab === "medium"}
                  onClick={() => handleTabClick("medium")}
                >
                  Medium Mode
                </TabButton>
              </TabHeader>
              {/* <TabContent>
                {activeTab === "easy" && <div>easy</div>}
                {activeTab === "hard" && <div>Hard</div>}
              </TabContent> */}
            </TabContainer>

            <Table columns={columns} data={tableData} />
          </DataSet>
        </WrapContent>
      </SoonSection>
    </ContactContainer>
  );
};

export default UserInfo;
