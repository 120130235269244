import React from "react";
import { useTable, usePagination, Column } from "react-table";
import { CSSProperties } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

interface RowData {
  walletAddress: string;
  // Include other properties that you expect in the data
}
// Define the types for the columns and data
interface TableProps {
  columns: Column[];
  // data: object[];
  data: RowData[]; // Use the newly defined type
  navigateToDetails?: boolean; // Optional boolean prop
}

// Using the CSS-in-JS approach
const headerClass = {
  background: "rgba(65, 184, 190, 0.12)",
  backdropFilter: "blur(10px)",
  display: "grid",
  gridTemplateColumns: "1fr 4fr 3fr 2fr 2fr",
  marginBottom: "10px",
  padding: "15px",
  borderRadius: "10px"
};

const bodyClass = {
  background: "rgba(65, 184, 190, 0.12)",
  backdropFilter: "blur(10px)",
  display: "grid",
  gridTemplateColumns: "1fr 4fr 3fr 2fr 2fr",
  marginBottom: "10px",
  padding: "15px",

  borderRadius: "10px"
};

const tdClass: CSSProperties = {
  color: "rgb(0, 0, 0)",
  fontFamily: "SatoshiRegular",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "16px",
  letterSpacing: "0.14px",
  textAlign: "center",
};

const secondTdClass: CSSProperties = {
  fontFamily: "SatoshiRegular",
  wordBreak: "break-all",
  textAlign: "left",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "16px",
  letterSpacing: "0.14px",
};

const firstClass: CSSProperties = {
  fontFamily: "DrukWide",
  textAlign: "center",
  fontSize: "14px",
};

const fourClass: CSSProperties = {
  fontFamily: "DrukWide",
  textAlign: "center",
  fontSize: "14px",
};

const thClass: CSSProperties = {
  color: "rgb(85, 155, 22)",
  fontFamily: "SatoshiRegular",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "16px",
  margin: "5px 0px",
};

const Table: React.FC<TableProps> = ({ columns, data,navigateToDetails }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // Render the table

  const Pagination = styled.div`
    text-align: center;
    margin-top: 30px;
    span {
      font-family: "SatoshiRegular";
    }
    strong {
      font-family: "SatoshiRegular";
    }
  `;
  const navigate = useNavigate();

  const ToDetails = (walletAddress: any) => {
    navigate(`/userinfo/${walletAddress}`);
  };

  return (
    <>
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} style={headerClass}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={thClass}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}    style={
                navigateToDetails
                  ? { ...bodyClass, cursor: 'pointer' }
                  : bodyClass
              }  onClick={() => {
                // Only call the ToDetails function if the navigateToDetails prop is true
                if (navigateToDetails) {
                  // Assuming the ID you want to use is available on the row object
                  const rowData = row.original as RowData;
                  ToDetails(rowData.walletAddress);
                }
              }} >
                {row.cells.map((cell, index: any) => {
                  let style;
                  if (index === 0) {
                    style = firstClass;
                  } else if (index === 1) {
                    style = secondTdClass;
                  } else if (index === 4) {
                    style = fourClass;
                  } else {
                    style = tdClass;
                  }
                  return (
                    <td {...cell.getCellProps()} style={style}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination controls */}
      <Pagination>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </Pagination>
    </>
  );
};

export default Table;
