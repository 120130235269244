import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ThemeState {
  currentTheme: {
    name: string;
    background: string;
    text: string;
  };
}

const initialState: ThemeState = {
  currentTheme: {
    name: 'light',
    background: '#ffffff',
    text: '#000000',
  },
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      if (state.currentTheme.name === 'light') {
        state.currentTheme = {
          name: 'dark',
          background: '#282c34',
          text: '#ffffff',
        };
      } else {
        state.currentTheme = {
          name: 'light',
          background: '#ffffff',
          text: '#000000',
        };
      }
    },
  },
});

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;



//we are defining a ThemeState interface to define the shape of the theme slice state. We are also importing the DefaultTheme and lightTheme objects that we defined earlier in our theme file.

//The themeSlice object is created using the createSlice function, which takes an object with a name, initialState, and reducers property. We define a single setTheme reducer that takes a DefaultTheme object and updates the currentTheme property in the state.

//Finally, we export the setTheme action and the themeSlice.reducer function.