import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RootState } from "./store/store";
import { DefaultTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import RoutesList from "./RoutesList";
import Navbar from "./components/Navbar";
import styled from "styled-components";
import { GlobalStyle } from "../src/styles/globalStyle";
import Footer from "./components/Footer";




const Wrapper = styled.div`
  max-width: 2000px;
  margin: 0 auto;
  width: 100%;
`;

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <BrowserRouter basename="/">
        <Navbar />
        <Wrapper>
          <RoutesList />
        </Wrapper>
        <Footer/>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
