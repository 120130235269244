import styled from 'styled-components';
import { colors } from '../styles/colors';

const SelectWrapper = styled.div`
  position: relative;
  margin: 10px;
  width: 150px;
  @media (min-width: 800px) {
    width: 180px;
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid ${colors.green};
  font-family: SatoshiRegular;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  width: 100%;
  outline:none;
  cursor:pointer;
  -webkit-appearance: none; // Removes the default arrow in Webkit browsers
  -moz-appearance: none; // Removes the default arrow in Mozilla browsers
  background: transparent; // Make sure the background is transparent so the custom arrow is visible
`;

const Arrow = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; // Make sure clicks on the arrow are passed through to the select element

  &::before {
    content: '▼'; // Custom arrow character
    font-size: 10px;
    color: #333; // Match the color of the text
  }
`;

function Dropdown({ label, options, onChange }:any) {
  const handleChange = (e:any) => {
    onChange(e.target.value);
  };

  return (
    <SelectWrapper>
      {/* {label && <label>{label}</label>} */}
      <Select onChange={handleChange}>
        {options.map((option:any, index:any) => (
          <option key={index} value={option.value}>
            {option.heading}
          </option>
        ))}
      </Select>
      <Arrow />
    </SelectWrapper>
  );
}

export default Dropdown;
