import React from "react";
import styled from "styled-components";

import footer from "../assets/images/footer1.svg";

import icon1 from "../assets/images/icon1.svg";

import icon2 from "../assets/images/icon2.svg";
import icon3 from "../assets/images/icon3.svg";

import { colors } from "../styles/colors";

const FooterWrapper = styled.div`
  background-image: url(${footer});
  background-size: cover;
  background-position: top;
  height: 427px;

  @media (min-width: 700px) {
    height: 340px;
  }

  h4 {
    padding-top: 35px;
  }
`;

// Styled Components
const FooterContainer = styled.footer`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 20px;
  color: white;
  @media (min-width: 700px) {
    padding: 0 40px;
  }
`;

const SixColumn = styled.div`
  grid-column: span 12;
  margin-top: 60px;
  h2 {
    font-family: DrukWide;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: ${colors.black};
  }
  strong {
    font-family: DrukWide;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: ${colors.green};
  }

  p {
    font-family: SatoshiMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: ${colors.black};
  }

  @media (min-width: 768px) {
    grid-column: span 6;
  }
`;

const TwoColumn = styled.div`
  grid-column: span 12;
  margin-top: 60px;
  display: none;

  h2 {
    font-family: DrukWide;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: ${colors.black};
  }

  a {
    font-family: SatoshiLight;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: ${colors.black};
    cursor: pointer;
    text-decoration: none;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin-top: 20px;
  }

  @media (min-width: 700px) {
    grid-column: span 2;
    display: block;
  }
`;

const IconWrap = styled.div`
  display: flex;
  img {
    margin-right: 20px;
  }
`;

const FourColumn = styled.div`
  grid-column: span 12;
  margin-top: 60px;

  @media (min-width: 768px) {
    grid-column: span 4;
  }
`;

const Discord = () => {
  const url = 'https://discord.com/invite/wqBreaMc'; // Replace with the URL you want to redirect to
  window.open(url, '_blank');
};

const Twitter = () => {
  const url = 'https://twitter.com/MetalityX'; // Replace with the URL you want to redirect to
  window.open(url, '_blank');
};

const Telegram = () => {
  const url = 'https://t.me/+-QYcYyr3KWpmZDVl'; // Replace with the URL you want to redirect to
  window.open(url, '_blank');
};




const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
      <SixColumn>
        <div style={{ display: "flex" }}>
          <strong>Swing </strong>
          <h2>To earn</h2>
        </div>
        <p>
         Join us for a round of Golf
        </p>

        <IconWrap>
          <img  onClick={Twitter} src={icon1} alt="" />
          <img  onClick={Telegram} src={icon2} alt="" />
          <img  onClick={Discord} src={icon3} alt="" />
        </IconWrap>

        <p style={{ marginTop: "70px" }}>Copyright © Swing to Earn 2023</p>
      </SixColumn>
      {/* <TwoColumn>
        <h2>General</h2>
        <ul>
          <li>
            <a href="Home">Home</a>
          </li>
          <li>
            <a href="Home">partner</a>
          </li>
          <li>
            <a href="Home">Home</a>
          </li>
        </ul>
      </TwoColumn> */}

      <TwoColumn>
        <h2>Social</h2>
        <ul>
          <li>
            <a  onClick={Twitter} href="Home">Twitter</a>
          </li>
          <li>
            <a onClick={Discord} href="Home">Discord</a>
          </li>
          <li>
            <a  onClick={Telegram} href="Home">Telegram</a>
          </li>
        </ul>
      </TwoColumn>
    </FooterContainer>
  </FooterWrapper>
);

export default Footer;
