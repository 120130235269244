import { combineReducers, configureStore } from '@reduxjs/toolkit';
import themeReducer from './themeSlice';

const rootReducer = combineReducers({
  theme: themeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export default store;


//we are importing the themeReducer function that we defined in the themeSlice.ts file.
//We are then passing the themeReducer function to the configureStore function to create the store.
