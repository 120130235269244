import React from 'react';
import {  Routes, Route } from 'react-router-dom';

import Contact from './pages/Contact';
import Landing from './pages/landing/Landing';
import Userinfo from './pages/Userinfo';


const RoutesList: React.FC = () => {
  return (

    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/data" element={<Contact />} />
      <Route path="/userinfo/:id" element={<Userinfo />} /> {/* Add this line */}
    </Routes>
  );
};

export default RoutesList;
