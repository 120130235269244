import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../styles/colors";

interface ArrowIconProps {
  isOpen: boolean;
}

const AccordionWrapper = styled.div`
margin:20px 20px 0 20px;

@media (min-width: 700px) {
  margin:50px 50px 0 50px;
}

@media (min-width: 1024px) {
  margin:50px 100px 0 100px;
}
`;

const AccordionContainer = styled.div`
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 50px;
  padding: 0;
  @media (min-width: 700px) {
    padding: 10px;
  }
`;
const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 14px;
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.833px;
    text-transform: uppercase;

    @media (min-width: 700px) {
      font-size: 20px;
    }
    @media (min-width: 1024px) {
      font-size: 28px;
    }
  }
  p {
    font-size: 16px;
    font-family: DrukWide;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    color: ${colors.green};
    margin-right: 20px;

    @media (min-width: 700px) {
      margin-right: 50px;
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      margin-right: 100px;
      font-size: 25px;
    }
  }
`;

const ArrowIcon = styled.span<ArrowIconProps>`
  margin-left: auto;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
  cursor: pointer;
`;

const AccordionContent = styled.div<ArrowIconProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding: 10px;

  p {
    font-family: SatoshiLight;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left:0;

    @media (min-width: 700px) {
      margin-left:50px;
     }

    @media (min-width: 1024px) {
     margin-left:130px;
    }
  }
`;

const accordionItems = [
  {
    title: "What is Swing to Earn?",
    content:
      "Swing to Earn is a revolutionary, blockchain-based golf game that allows players to earn cryptocurrency rewards by completing golf challenges. Our beta version will introduce the One Shot Challenge, letting you experience the thrill of our game while also having a chance to earn",
  },
  {
    title: "How can I play the game?",
    content:
      "As we're launching our beta version, you can participate in the One Shot Challenge. This mode gives you a single shot to win. If you're successful, you earn rewards. Detailed instructions about gameplay will be available when the game launches.",
  },
  {
    title: "On which blockchain is Swing to Earn available?",
    content:
      "For our beta version, Swing to Earn will be available on the Polygon blockchain. This ensures fast transactions and low fees, providing an optimal gaming experience.",
  },
  {
    title: "How do I earn rewards in Swing to Earn?",
    content:
      "In the beta version of Swing to Earn, you can earn rewards by successfully completing the One Shot Challenge. As we further develop the game, additional opportunities to earn rewards through different modes and challenges will be introduced.",
  },
  {
    title: "Is Swing to Earn secure?",
    content:
      "  Absolutely. Swing to Earn is built on blockchain technology, which ensures secure, transparent, and fair gameplay. Our smart contracts have been carefully audited for security to ensure a safe gaming environment.",
  },
];

const ParentComponent = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    0
  );

  return (
    <>
      {accordionItems.map((item, index) => (
        <Accordion
          key={index}
          title={item.title}
          isOpen={openAccordionIndex === index}
          onToggle={() =>
            setOpenAccordionIndex(openAccordionIndex === index ? null : index)
          }
        >
          {item.content}
        </Accordion>
      ))}
    </>
  );
};

const Accordion = ({ title, children, isOpen, onToggle }: any) => {
  return (
    <AccordionWrapper>
         <AccordionContainer>
      <AccordionHeader onClick={onToggle}>
        <p>01</p>
        <span>{title}</span>
        <ArrowIcon isOpen={isOpen}>▼</ArrowIcon>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>
        <p>{children}</p>
      </AccordionContent>
    </AccordionContainer>
    </AccordionWrapper>
 
  );
};

export default ParentComponent;
