
export interface Colors {
  green: string
  lightBackground: string
  darkBackground: string
  text: string
  white: string
  black: string
}

export const colors: Colors = {
  green: '#559B16',
  lightBackground: '#fff',
  darkBackground: '#000',
  text: '#394E5B',
  white: '#ffffff',
black :'#000000'
}
